<template>
 <teleport to="body">
    <div class="toastr-wrapper " v-if="isShowToastr">
        <div class="container toastr-wrapper__2">
            <div class="toastr-outliner">
                <div class="toastr-container" @click="handleClose()" :class="{'active' : isShowToastrContent}">
                    <span class="toastr-text">{{$t(text)}}</span>
                    <img class="toastr-icon" src="../../../static/images/icons/icon_close_grey.png">
                </div>
            </div>
        
        </div>
    
    </div>
 </teleport>
</template>

<script>
export default {
    props:{
        text: {
            type: String,
            default : ''
        },
    },
    data() {
        return {
            isShowToastr : false,
            isShowToastrContent : false,
            closeTime: 2000 //2s
        }
    },
    methods: {
        handleOpen(){
            this.isShowToastr = true;
            setTimeout(() => {
                this.isShowToastrContent = true;
            }, 100);   

            setTimeout(() => {
               this.handleClose()
            }, this.closeTime);
        },
        handleClose(){
             this.isShowToastrContent = false;
                setTimeout(() => {
               this.isShowToastr = false;
            }, 300);
        }
    },
}
</script>

<style scoped>
.toastr-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    min-height: 12rem;
    z-index: 11;
}
.toastr-wrapper__2{
    min-height: inherit;
}
.toastr-outliner{
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
    margin-top: 6%;
}
.toastr-container{
    padding: .8rem;
    background-color: white;
    color: black;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all .3s;
    transform: translate(0, 10rem);
    min-width: 16.8125rem;
}
.toastr-container.active {
    transform: translate(0, 0);
}
.toastr-text{
    margin-right: 1rem;
}
.toastr-icon{
   cursor: pointer;
}
</style>
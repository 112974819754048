<template>
    <div v-if="!isShowDrawerContent" class="social-media__wrapper">
        <div class="social-media__container" v-for="(o, index) in socialMediaShare" :key="index" @click="handleSocialMedia(o)">
            <img :src="require(`../../../static/images/icons/social_media/${o.iconUrl}.png`)">
        </div>
    </div>

    <ToastrDesktop ref="toastrDesktop" :text="'LINK_COPIED'" ></ToastrDesktop>
    <!-- <Toastr ref="toastr" :text="'LINK_COPIED'" ></Toastr>  -->
</template>

<script>
import ToastrDesktop from '@/components/toastr/ToastrDesktop.vue'
import Toastr from '@/components/toastr/Toastr.vue'

import {mapGetters,mapActions} from 'vuex'
export default {
    components:{
        ToastrDesktop,
        Toastr   
    },

    data() {
        return {
            socialMediaShare: [
                 {
                    id: "fb",
                    name: "SHARE_ON_Facebook",
                    socialMediaUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
                    iconUrl:'icon_fb'
                },
                {    
                    id: "twitter",
                    name: "SHARE_ON_Twitter",
                    socialMediaUrl: 'https://twitter.com/share?url=',
                    iconUrl:'icon_twitter'
                },
                {    
                    id: "line",
                    name: "SHARE_ON_Line",
                    socialMediaUrl: 'https://social-plugins.line.me/lineit/share?url=',
                    iconUrl:'icon_line'
                },
                {    
                    id: "selfShare",
                    name: "COPY_LINK",
                    socialMediaUrl: "",
                    iconUrl:'icon_share'
                }
            ],
        }
    },
    computed:{
        ...mapGetters([              
                'isMobile',
           ]),
    },
    methods: {
          ...mapActions([
            "toastrChangeEvent"
     
        ]),
        handleSocialMedia(o) {
            if (o.id === 'selfShare') {
                let value = window.location.href;
                /* Copy the text inside the text field */
                navigator.clipboard.writeText(value);
          
                if (this.isMobile) {
                     let paramsToastr={
                        isOpen:true,
                        msg:'LINK_COPIED',   
                        info: ''   
                    }
                    this.toastrChangeEvent(paramsToastr)
                    // this.$refs.toastr.handleOpen(); 
                } else {
                    this.$refs.toastrDesktop.handleOpen(); 
                }
            } else {
                let href = window.location.href;
                let url = o.socialMediaUrl + href;
                this.popupwindow(url,'', 550, 570);
            }
        },

        popupwindow(url, title, w, h) {
            var left = (screen.width/2)-(w/2);
            var top = (screen.height/2)-(h/2);
            return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
        },
    },
}
</script>

<style>
.social-media__wrapper{
    display: flex;
}
.social-media__container{
    margin-right: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
}
</style>
<template>
    <div  v-if="isShowVideo" class="display-flex-center video-wrapper">
        <div class="video-container">
            <div class="display-flex-fe">
                <div class="video-close" @click="closeCurrentVideo()"></div>
            </div>    
            <!-- <div class="video-loading"><Loading ></Loading> </div> -->
               
            <video  v-if="isAudioType" class="video-video" autoplay playsinline  controls :src="currentVideoUrl" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></video> 
            <iframe :src="currentVideoUrl" allow="autoplay"  class="video-iframe" v-else></iframe>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        currentVideoUrl :{
            type : String
        },
    },
    data() {
        return {
            isShowVideo :false,
            isAudioType : false,
        }
    },

      computed: {
        currentVideoUrl() {
            let currentVideoUrl = this.currentVideoUrl;  

            if (this.$tools.isAudioType(currentVideoUrl)) {              
                this.isAudioType = true;
            } else {
                this.isAudioType = false;
            }

            currentVideoUrl =  this.$tools.updateUrlParameter(currentVideoUrl,'autoplay',1);

            return currentVideoUrl;
        }
    },
    watch:{
		currentVideoUrl:{
			deep:true,
	        	handler(newVal,oldVal) {  
                 
                 
			}
		}
   },
    methods: {
        openCurrentVideo(){
            this.isShowVideo = true;
        },
        closeCurrentVideo(e){
            this.isShowVideo = false;
        }
    },
}
</script>

<style>
   
    .video-wrapper{
        position: fixed;
        z-index:3;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgb(0 0 0 / 80%);
    }
    .video-close{
        width:1rem;
        height: 1rem;
        display: flex;
        float: right;
        margin: 0 1rem .5rem 0;
        cursor: pointer;
        background: url('../../../static/images/icons/icon_close_white.png') center/cover no-repeat;
    }
    .video-container{
        width: 50%;
        height: 50%;
         position: relative;
        display: flex;
        flex-direction: column;
    }
    .video-container >iframe{
        border: none;
        width: 100%;
    }
    .video-loading{
        position: absolute;
        top: 1rem;
        left: 0;
        right: 0;
        /* bottom: 0; */
        pointer-events: none;
    }
    .video-video{
        height: 89%;
        z-index: 9;
    }
    .video-iframe{
        height: 89%;
         z-index: 9;
    }
    @media (max-width: 768px){
        .video-container{
           width: 100%;
        }
        .video-video{
            height: 17rem;
        }
         .video-iframe{
            height: 17rem;
        }
    }
</style>
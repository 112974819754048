<template>
  <div class="match-detail__header-wrapper">
    <div class="match-detail__header-info">
      <div
        class="match-detail__header-info__match"
        v-if="data.leagueName !== null"
      >
        {{ data.leagueName }}
      </div>
      <div class="match-detail__header-info__country">{{ data.country }}</div>
    </div>
    <div class="match-detail__header-info__opponent display-flex-center">
      <Navigation
        :id="data.homeTeamId"
        :category="'team'"
        :name="data.homeTeamName"
        class="display-flex-sb"
      >
        <!-- <router-link  :to="{path:`/${currentLocale}/${currentSportType}/team/${data.homeTeamId}`}" class="display-flex-sb"> -->
        <div class="display-flex-center ">
          <div class="mr-1rem match-detail__header-info__home-name">
            {{ data.homeTeamName }}
          </div>
          <Icon :logoUrl="data.homeLogo"></Icon>
        </div>
      </Navigation>
      <!-- </router-link > -->
      <div class="mr-1rem ml-1rem">
        <div class="display-flex-center">
          <div class="match-detail__header-info__score">
            {{ data.homeTeamScore }}
          </div>
          <div>:</div>
          <div class="match-detail__header-info__score">
            {{ data.awayTeamScore }}
          </div>
        </div>
        <div
          v-if="data.matchStatus && !data.matchStatus.includes(':')"
          class="match-detail__header-info__match-status display-flex-center"
        >
          {{ data.matchStatus }}
        </div>
      </div>

      <!-- <router-link  :to="{path:`/${currentLocale}/${currentSportType}/team/${data.awayTeamId}`}"  class="display-flex-sb"> -->
      <Navigation
        :id="data.awayTeamId"
        :category="'team'"
        :name="data.awayTeamName"
        class="display-flex-sb"
      >
        <div class="display-flex-center">
          <Icon :logoUrl="data.awayLogo" class="mr-1rem"></Icon>
          <div class="match-detail__header-info__away-name">
            {{ data.awayTeamName }}
          </div>
        </div>
        <!-- </router-link>    -->
      </Navigation>
    </div>

    <div
      class="match-detail__header-info__penatly display-flex-center"
      style="margin-bottom:0"
      v-if="data.awayTeamPenaltyScore"
    >
      <Navigation
        :id="data.homeTeamId"
        :category="'team'"
        :name="data.homeTeamName"
        class="display-flex-sb relative"
      >
        <!-- <router-link  :to="{path:`/${currentLocale}/${currentSportType}/team/${data.homeTeamId}`}" class="display-flex-sb relative"> -->
        <div class="display-flex-center mr-1rem">
          <div
            class="mr-1rem match-detail__header-info__home-name"
            style="width: 14.5rem"
          ></div>
          <div class="match-detail__header-info__penatly-label left">
            <img
              src="../../../static/images/label/label_pen_win.png"
              v-if="data.homeTeamPenaltyScore > data.awayTeamPenaltyScore"
            />
          </div>
          <!-- <Icon :category="'team'" :id=data.homeTeamId ></Icon> -->
        </div>
        <div class="match-detail__header-info__score">
          {{ data.homeTeamPenaltyScore }}
        </div>
        <!-- </router-link > -->
      </Navigation>
      <div>:</div>
      <Navigation
        :id="data.awayTeamId"
        :category="'team'"
        :name="data.awayTeamName"
        class="display-flex-sb relative"
      >
        <!-- <router-link  :to="{path:`/${currentLocale}/${currentSportType}/team/${data.awayTeamId}`}"  class="display-flex-sb relative"> -->
        <div class="mr-2rem match-detail__header-info__score">
          {{ data.awayTeamPenaltyScore }}
        </div>
        <div class="match-detail__header-info__penatly-label right">
          <img
            src="../../../static/images/label/label_pen_win.png"
            v-if="data.awayTeamPenaltyScore > data.homeTeamPenaltyScore"
          />
        </div>
        <div class="display-flex-center">
          <div class="icon-league mr-1rem"></div>
          <div class="match-detail__header-info__away-name"></div>
        </div>
        <!-- </router-link>              -->
      </Navigation>
    </div>
    <div
      class=" match-detail__header-info__penatly-title"
      v-if="data.awayTeamPenaltyScore"
    >
      {{ $t("PENALTY") }}
    </div>
    <div class="match-detail__header-info__date">
      <!-- <span v-if="currentLocale === 'vn'"
        >{{ $t(matchDateDay) }}{{ data.vnMatchDate }}</span
      >
      <span v-else-if="currentLocale === 'th'">{{ data.thMatchDate }}</span>
      <span v-else>{{ data.matchTime }}</span> -->
      {{
        currentLocale === "vn"
          ? data.vnMatchDate
          : currentLocale === "th"
          ? data.thMatchDate
          : data.matchTime
      }}
    </div>
    <!-- <div v-show="data.containLiveStream" class="w-100">
      <video
        id="videoLiveRef"
        class="video-js vjs-default-skin w-100"
        controls
        autoplay
      ></video>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import videojs from "video.js";

export default {
  props: {
    data: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters(["currentLocale"]),
  },
  watch: {
    data: {
      deep: true,
      handler(newVal, oldVal) {
        this.dateFormat();
      },
    },
  },

  data() {
    return {
      matchDateDay: "",
    };
  },
  mounted() {
    this.dateFormat();
    // this.playVideoLive();
  },

  methods: {
    dateFormat() {
      if (typeof this.data !== "undefined") {
        if (typeof this.data.matchTime !== "undefined") {
          let matchDate = new Date(this.data.matchTime);
          let FormatedMatchDate = `, ${matchDate.getDate()} tháng ${matchDate.getMonth() +
            1} năm ${matchDate.getFullYear()}, ${matchDate.getHours()} giờ ${(matchDate.getMinutes() <
          10
            ? "0"
            : "") + matchDate.getMinutes()} phút `;
          this.data.matchTime = this.data.matchTime;
          this.data.vnMatchDate = FormatedMatchDate;
          this.data.thMatchDate = `${this.$i18n.t(
            this.$tools.getDay(matchDate.getDay())
          )}, ${matchDate.getDate()} ${this.$i18n.t(
            this.$tools.getMonth(matchDate.getMonth())
          )} ${matchDate.getFullYear()}, ${(matchDate.getHours() < 10
            ? "0"
            : "") + matchDate.getHours()}:${(matchDate.getMinutes() < 10
            ? "0"
            : "") + matchDate.getMinutes()}`;
          this.matchDateDay = this.$tools.getFullDay(matchDate.getDay());
        }
      }
    },
  },
};
</script>

<style>
.match-detail__content-container {
  min-height: 50vh;
}
.match-detail__header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
}

.match-detail__header-info {
  text-align: center;
  margin-bottom: 1rem;
}
.match-detail__header-info__match {
  font-size: 0.88rem;
}
.match-detail__header-info__country {
  font-size: 0.6rem;
}
.match-detail__header-info__date {
  color: var(--color-grey-light);
  font-size: 0.88rem;
}
.match-detail__header-info__match-status {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 700;
}
.match-detail__header-info__home-name {
  width: 12rem;
  text-align: right;
  overflow: hidden;
}
.match-detail__header-info__home-name:hover {
  color: var(--color-hover);
}
.match-detail__header-info__away-name {
  width: 12rem;
  overflow: hidden;
}
.match-detail__header-info__away-name:hover {
  color: var(--color-hover);
}
.match-detail__header-info__penatly {
  border-top: 0.1rem solid #35454d;
  margin-top: 0.6rem;
  padding-top: 0.5rem;
}
.match-detail__header-info__penatly-title {
  font-size: 0.7rem;
  color: var(--color-grey-light);
  margin-bottom: 1rem;
}

.match-detail__header-info__penatly-label {
  top: 0.6rem;

  position: absolute;
  width: 2.3rem;
}
.match-detail__header-info__penatly-label.left {
  right: 3.4rem;
}
.match-detail__header-info__penatly-label.right {
  left: 3.4rem;
}
.match-detail__header-info__score {
  width: 2rem;
  text-align: center;
}
.match-detail__header-info__livevideo {
  width: 100%;
  height: auto;
}
</style>

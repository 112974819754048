<template>
 <teleport to="body">
    <div v-if="isShowDrawer">
        <div class="drawer-overlay" :class="{'active':isShowDrawerOverlay}" @click="closeDrawer()">
        </div>
        <ul class="drawer-wrapper" :class="{'active':isShowDrawerContent}">
            <!-- <li class="drawer-container">
                <router-link :to="{path:data}"  target="_blank" @click="$tools.openNewWindow($event)">
                    <img class="drawer-container__icon" src="../../../static/images/icons/icon_footer_home_unselect.png">
                    <span class="drawer-container__text">{{$t('MATCH_DETAILS')}}</span>
                </router-link >
            </li> -->
            <div v-if="isShowMediaSharing">
                <li class="drawer-container" v-for="(o, index) in socialMediaShareList" :key="index"  @click="handleSocialMedia(o)">
                    <img  class="drawer-container__icon" :src="require(`../../../static/images/icons/social_media/${o.iconUrl}.png`)">
                    <span class="drawer-container__text">{{$t(o.name)}}</span>
                </li> 
            </div>
        </ul>
    </div>
    <ToastrDesktop ref="toastrDesktop" :text="'LINK_COPIED'" ></ToastrDesktop>
    <!-- <Toastr ref="toastr" :text="'LINK_COPIED'" ></Toastr>  -->
 </teleport>
</template>

<script>
import ToastrDesktop from '@/components/toastr/ToastrDesktop.vue'
import Toastr from '@/components/toastr/Toastr.vue'

import config from '@/js/config.js'
import {mapGetters,mapActions} from 'vuex'
export default {
      components:{
        ToastrDesktop,
        Toastr   
    },
    props: {
        data: {
            type: String
        },
        isShowMediaSharing: {
            type: Boolean,
            default : false
        },
    },
   data(){
        return{
            isShowDrawer :false,
            isShowDrawerContent :false,

            socialMediaShareList: config.socialMediaShareList
        }
    },
 
   computed:{
        ...mapGetters([
            'isMobile',
            'currentLocale',
            'currentSportType',
        ]),  
    },
    methods: {
          ...mapActions([
            "toastrChangeEvent"
     
        ]),
        showDrawer(){
            this.isShowDrawer = true;
            setTimeout(() => {
                this.isShowDrawerOverlay = true;
                this.isShowDrawerContent = true;
            }, 100);
        },
        closeDrawer(){
            this.isShowDrawerContent = false;
            setTimeout(() => {
                 this.isShowDrawerOverlay = false;
                this.isShowDrawer = false;
            }, 100);
        },
         handleSocialMedia(o) {
            if (o.id === 'selfShare') {
                let value = window.location.href;
                /* Copy the text inside the text field */
                navigator.clipboard.writeText(value);
          
                if (this.isMobile) {
                      let paramsToastr={
                        isOpen:true,
                        msg:'LINK_COPIED',   
                        info: ''   
                    }
                    this.toastrChangeEvent(paramsToastr)
                    // this.$refs.toastr.handleOpen(); 
                } else {
                    this.$refs.toastrDesktop.handleOpen(); 
                }
            } else {
                let href = window.location.href
                let url = o.socialMediaUrl + href;
                this.popupwindow(url,'', 550, 570);
            }
        },
        popupwindow(url, title, w, h) {
            var left = (screen.width/2)-(w/2);
            var top = (screen.height/2)-(h/2);
            return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
        },
    },
}
</script>

<style>
.drawer-overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition:all.15s;
    z-index: 10;
}
.drawer-overlay.active{
    background-color: rgb(0 0 0 / 57%);
}
.drawer-wrapper{
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #F3F5F7;

    font-weight: 700;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 10;
    -webkit-transition: -webkit-transform .3s cubic-bezier(.4,0,.6,1);
    transition: -webkit-transform .3s cubic-bezier(.4,0,.6,1);
    transition: transform .3s cubic-bezier(.4,0,.6,1);
    transition: transform .3s cubic-bezier(.4,0,.6,1),-webkit-transform .3s cubic-bezier(.4,0,.6,1);
    transform: translateY(100%);
}
.drawer-wrapper.active{
    transform: translateY(0);
}
.drawer-container{
    padding: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.drawer-container__icon{
    margin-right: 1rem;
}
.drawer-container__text{
    color: var(--color-theme-2) !important;
}
</style>